<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd' : 'zhadminadd'" style="font-size: 14px;">
    <div style="text-align: right; z-index: 1000;">
      <el-button style="text-align: right;" size="small" type="primary" @click="isqpisboot = !isqpisboot">{{ isqpisboot
        ?
        $t('All.取消全屏') : $t('All.全屏') }}</el-button>
      <el-button size="small" type="danger"   @click="FQC_User(form, 7)">{{$t('All.紧急提交')}}</el-button>
      <el-button type="primary" size="small" @click="FQC_User(form, 0)">{{ this.$t('system.tijiao') }}</el-button>
      <el-button type="success" size="small" @click="FQC_User(form, 3)">{{ this.$t('system.zancun') }}</el-button>
      <el-button type="primary" size="small" @click="dialogVisible = true">{{ this.$t('system.fjian') }}</el-button>
    </div>
    <h2 style="text-align: center;">{{ this.$t('system.company') }}</h2>
    <h3 style="text-align: center;">{{ this.$t('system.成品检验记录') }}</h3>
    <h3 style="text-align: center;">{{ this.$t('system.systemid') }}：{{ form.Numbers }}</h3>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1" align="center" cellpadding="10"
        style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width: 100%;">
        <tr>
          <td>{{ this.$t('FQC.制造班别') }}</td>
          <td>{{ this.$t('FQC.工单号') }}</td>
          <td>{{ this.$t('FQC.客户') }}</td>
          <td colspan="3">{{ this.$t('FQC.品名') }}</td>
          <td colspan="2">{{ this.$t('IQC.料号') }}</td>
          <td colspan="2">{{ this.$t('system.InspectionDate') }}</td>
        </tr>
        <tr>
          <td class="tdd">
            <el-select v-model="form.Banbi" allow-create filterable :placeholder="this.$t('FQC.请选择')">
              <el-option v-for="i in $PublicJs.Banbi" :key="i.value" :label="i.label" :value="i.value">
              </el-option>
            </el-select>
          </td>
          <td class="tdd"><el-input disabled v-model="form.Workorder"></el-input></td>
          <td class="tdd"><el-input v-model="form.Customer"></el-input></td>
          <td colspan="3" class="tdd"><el-input v-model="form.Product_name"></el-input></td>
          <td class="tdd" colspan="2">{{ form.Item_no }}</td>
          <td colspan="2" class="tdd">{{ form.Timsdate }}</td>
        </tr>
        <tr>
          <td class="tdd">{{ this.$t('All.单位') }}</td>
          <td class="tdd">{{ this.$t('FQC.尺寸') }}</td>
          <td class="tdd">{{ this.$t('FQC.包装') }}</td>
          <td class="tdd">{{ this.$t('FQC.适配') }}</td>
          <td colspan="1" class="tdd">{{ this.$t('FQC.颜色') }}</td>
          <td class="tdd">{{ this.$t('FQC.插头') }}</td>
          <td colspan="2" class="tdd">CABLE/MOLD</td>
          <td colspan="2">LOT NO.</td>
        </tr>
        <tr>
          <td class="tdd">
            <el-select v-model="form.Company" filterable allow-create :placeholder="$t('All.单位')"
              @change="chuangjcompany" :style="{ width: '100%' }">
              <el-option label="G" value="G"></el-option>
              <el-option label="KG" value="KG"></el-option>
              <el-option label="PC" value="PC"></el-option>
              <el-option label="M" value="M"></el-option>
              <el-option label="KM" value="KM"></el-option>
            </el-select>
          </td>
          <td class="tdd">
            <el-select v-model="form.Size" :placeholder="this.$t('FQC.请判定')">
              <el-option label="Pass" value="Pass"></el-option>
              <el-option label="Fail" value="Fail"></el-option>
              <el-option label="NA" value="NA"></el-option>
            </el-select>
          </td>
          <td class="tdd">
            <el-select v-model="form.Packing" :placeholder="this.$t('FQC.请判定')">
              <el-option label="Pass" value="Pass"></el-option>
              <el-option label="Fail" value="Fail"></el-option>
              <el-option label="NA" value="NA"></el-option>
            </el-select>
          </td>
          <td class="tdd">
            <el-select v-model="form.Other" :placeholder="this.$t('FQC.请判定')">
              <el-option label="Pass" value="Pass"></el-option>
              <el-option label="Fail" value="Fail"></el-option>
              <el-option label="NA" value="NA"></el-option>
            </el-select>
          </td>
          <td colspan="1" class="tdd"><el-input v-model="form.Colour"></el-input></td>
          <td class="tdd">
            <el-select v-model="form.Plug" :placeholder="this.$t('FQC.请判定')">
              <el-option label="Pass" value="Pass"></el-option>
              <el-option label="Fail" value="Fail"></el-option>
              <el-option label="NA" value="NA"></el-option>
            </el-select>
          </td>
          <td colspan="2" class="tdd">
            <el-select v-model="form.Cable_MOLD" :placeholder="this.$t('FQC.请判定')">
              <el-option label="Pass" value="Pass"></el-option>
              <el-option label="Fail" value="Fail"></el-option>
              <el-option label="NA" value="NA"></el-option>
            </el-select>
          </td>
          <td colspan="2" class="tdd"><el-input v-model="form.Lot_NO"></el-input></td>
        </tr>
        <tr>
          <td class="tdd">{{ this.$t('FQC.抽样数') }}</td>
          <td class="tdd">{{ this.$t('FQC.交货数') }}</td>
          <td class="tdd">{{ this.$t('FQC.入库数') }}</td>
          <td class="tdd">{{ this.$t('FQC.箱数') }}</td>
          <td class="tdd" colspan="1">{{ this.$t('IQC.抽样类型') }}</td>
          <td class="tdd" colspan="1">{{ this.$t('FQC.参考文件') }}</td>
          <td colspan="2" class="tdd">{{ this.$t('IQC.检验基准表') }}</td>
          <td colspan="2" class="tdd">{{ this.$t('FQC.图面编号') }}</td>
        </tr>
        <tr>
          <td class="tdd"><el-input v-model="form.Sampling"></el-input></td>
          <td class="tdd"><el-input v-model="form.Delivery"></el-input></td>
          <td class="tdd"><el-input v-model="form.Warehousing"></el-input></td>
          <td class="tdd"><el-input v-model="form.Case_number" onkeyup="value=value.replace(/[^0-9]/g,'')"
              @blur="finalFormat"></el-input></td>
          <td class="tdd">
            <el-select v-model="form.Sampling_type" @change="finalFormat" :placeholder="this.$t('IQC.请选择类型')">
              <el-option :label="this.$t('IQC.全检')" :value="3"></el-option>
              <el-option :label="this.$t('IQC.加严')" :value="1"></el-option>
              <el-option :label="this.$t('IQC.正常')" :value="0"></el-option>
              <el-option :label="this.$t('IQC.减量')" :value="2"></el-option>
            </el-select>
          </td>
          <td class="tdd"><el-input v-model="form.Standard2"></el-input></td>
          <td class="tdd" colspan="2">
            <el-tooltip effect="dark" :content="form.Inspection_benchmark" placement="top-start">
              <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                <span @click="fileopen(form.Inspection_benchmarkurl)">{{ form.Inspection_benchmark }}</span>
              </div>
            </el-tooltip>
          </td>
          <td class="tdd" colspan="2">
            <el-tooltip effect="dark" :content="form.Drawing_number" placement="top-start">
              <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                <span @click="fileopen(form.Drawing_numberurl)">{{ form.Drawing_number }}</span>
              </div>
            </el-tooltip>
          </td>
        </tr>
        <tr>
          <td colspan="6">{{ this.$t('FQC.AQL标准') }}</td>
          <td colspan="4">{{ this.$t('All.抽样箱号') }}</td>
        </tr>
        <tr>
          <td colspan="6">{{ this.$t('FQC.抽样标准') }}</td>
          <td colspan="4">{{ form.Samplingboxid }}</td>
        </tr>
        <tr>
          <td>{{ this.$t('IQC.检验项目') }}</td>
          <td colspan="2">{{ this.$t('IQC.检验标准') }}</td>
          <td colspan="1">{{ this.$t('All.检验工具') }}</td>
          <td colspan="2">{{ this.$t('All.判定基准') }}</td>
          <td colspan="1">{{ this.$t('All.实验频率') }}</td>
          <td colspan="1">{{ this.$t('IQC.判定') }}</td>
          <td colspan="2">{{ this.$t('IQC.备注') }}</td>
        </tr>
        <tr v-for="(item, index) in form.Fqctestlist" :key="'Fqctestlist' + index">
          <td colspan="1" class="tdd">{{ item.Title }}</td> <!--:rowspan="item.Index" v-if="(item.Index !== 0)"-->
          <td colspan="2" class="tdd">{{ item.Standard }}</td>
          <td colspan="1" class="tdd">{{ item.Tool }}</td>
          <td colspan="2" class="tdd" style="text-align: left;"><span>{{ item.Benchmark }}</span></td>
          <td colspan="1">{{ $PublicJs.Frequencyfun(item.Frequency) }}</td>
          <td colspan="1">
            <el-select :disabled="((item.Determinetype == 1))" v-model="item.Determine"
              :placeholder="item.Determinetype == 1 ? '实验室判定' : $t('IQC.请判定')">
              <el-option label="Pass" value="Pass"></el-option>
              <el-option label="Fail" value="Fail"></el-option>
              <el-option label="NA" value="NA"></el-option>
            </el-select>
          </td>
          <td colspan="2">
            <el-input type="textarea" v-if="(item.Determinetype == 0)" :placeholder="$t('All.备注')" :rows="3"
              v-model="item.Remarks"></el-input>
            <el-link @click="lookshiyan(item, index)" type="primary"
              v-if="item.Determinetype == 1 && item.Remarks.length > 0 && item.Remarks != ''">{{ item.Remarks
              }}</el-link>
            <el-button size="mini" v-if="item.Determinetype == 1 && (item.Remarks.length === 0 || item.Remarks == '')"
              type="primary" @click="Testbox(item, index)">{{ $t('All.送测单') }}</el-button>
            <el-button style="margin-left: 0 !important;margin-top: 5px;" size="mini"
              v-if="item.Determinetype == 1 && (item.Remarks.length === 0 || item.Remarks == '')" type="warning"
              @click="mzTestbox(item, index)">{{ $t('All.免做单') }}</el-button>
          </td>
        </tr>
        <tr>
          <td class="tdd" rowspan="2">{{ this.$t('FQC.规格要求') }}</td>
          <td class="tdd" colspan="2">{{ this.$t('FQC.量测仪器编号') }}</td>
          <td colspan="7" class="tdd"><el-input v-model="form.Yqish1"></el-input></td>
        </tr>
        <tr>
          <td colspan="1" class="tdd">{{ $t('All.单位') }}/{{ $t('All.标注') }}</td>
          <td colspan="5" class="tdd">{{ this.$t('FQC.尺寸检验记录') }}</td>
          <td colspan="1" class="tdd">{{ this.$t('FQC.最大值') }}</td>
          <td colspan="1" class="tdd" style="min-width: 100px;">{{ this.$t('FQC.最小值') }}</td>
          <td colspan="1" class="tdd" style="min-width: 150px;">{{ this.$t('FQC.判定') }}</td>
        </tr>
        <tr v-for="(text, index) in form.Parcss" :key="index">
          <td class="tdd" style="min-width: 100px; font-size: 14px;">{{ text.Text }}</td>
          <td class="tdd" style="min-width: 100px; font-size: 14px;">{{ text.Text1 }}</td>
          <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text2" v-enter-next-input></el-input></td>
          <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text3" v-enter-next-input></el-input></td>
          <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text4" v-enter-next-input></el-input></td>
          <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text5" v-enter-next-input></el-input></td>
          <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text6" v-enter-next-input name="Text5"
              @change="Keyenter(text, index)"></el-input></td>
          <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text7"></el-input></td>
          <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text8"></el-input></td>
          <td class="tdd">
            <el-select v-model="text.Text9" :placeholder="$t('All.请选择')" @change="addlist(text, index, $event)">
              <el-option label="Pass" value="Pass"></el-option>
              <el-option label="Fail" value="Fail"></el-option>
              <el-option :label="$t('All.判定')" :value="$t('All.判定')"></el-option>
              <el-option :label="$t('All.增加')" :value="$t('All.增加')"></el-option>
              <el-option :disabled="form.Parcss.length == 1" :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="tdd" colspan="5"><el-input :placeholder="this.$t('FQC.请输入异常描述')" v-model="form.Ycms"
              type="textarea" :rows="5"></el-input></td>
          <td style="text-align: left;line-height: 2vw;" colspan="5">{{ form.Beizhu }}</td>
        </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{ this.$t('FQC.核准')
        }}:
            {{ form.Hz }}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{ this.$t('FQC.检验员')
        }}: {{ form.Jyy }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;">
          <h6></h6>
        </el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span
            style="font-size: 1.5vw;">NYP1001-02-J</span></el-col>
      </el-row>
    </div>
    <el-dialog :title="this.$t('system.fjian')" :visible.sync="dialogVisible" width="80%" :before-close="handleClose"
      append-to-body>
      <fqcUpload :isdata="isdata" :fqcadmin="form"></fqcUpload>
    </el-dialog>
    <el-dialog :title="$t('All.请输入已做实验单号')" :visible.sync="yzdialogVisible" width="500px" :before-close="handleClose"
      append-to-body>
      {{ $t('All.实验单号') }}：<el-input style="width: 300px;border: 1px solid #DCDFE6 !important;border-radius: 5px;"
        v-model="Mz_Numbers"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="yzdialogVisible = false">{{ $t('All.取消') }}</el-button>
        <el-button type="primary" @click="Gettestbaogao()">{{ $t('All.确认') }}</el-button>
      </span>
    </el-dialog>
    <NewfqcTestsheet v-if="Texttable" :formxin="form" :Test_Numbers="Test_Numbers" :Texttablebool="Texttablebool"
      :Test_Item="Test_Item" :Test_Index="Test_Index" :Test_Supplier="Test_Supplier"
      :Test_Product_name="Test_Product_name" :Texttablefunadd="Texttablefunadd"></NewfqcTestsheet>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import fqcUpload from './Upload/NewFQCUpload.vue'
import NewfqcTestsheet from './Upload/NewfqcTestsheet'
import Vue from 'vue'
// 自定义指令，实现按下enter后，光标自动跳转下一个输入框
Vue.directive('enterNextInput', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      if (charcode === 13) {
        try {
          var dom = document.getElementsByTagName('input')
          for (var i = 0; i < dom.length; i++) {
            if (dom[i] === document.activeElement) {
              if (dom[i].name === 'Text5') {
                dom[i + 6].focus()
              } else {
                dom[i + 1].focus()
              }
              return
            }
          }
        } catch (error) {

        }
      }
    })
  }
})
export default {
  data () {
    return {
      form: {},
      dialogVisible: false,
      isdata: true,
      Texttable: false,
      isqpisboot: false,
      Test_Item: '',
      Test_Index: '',
      Test_Numbers: '',
      Test_Supplier: '',
      Test_Product_name: '',
      Mz_Numbers: '',
      Mz_Index: '',
      yzdialogVisible: false,
      Mz_day: ''
    }
  },
  components: {
    fqcUpload,
    NewfqcTestsheet
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    if (this.$store.state.UserData === null) {
      this.Addtab(this.$t('All.新FQC检验单'), 'NewFQCSeleItem')
      this.RemoveTab(this.$t('All.新FQC检验单') + '-' + this.$store.state.UserData.Numbers)
      this.$message.error(this.$t('All.模版不存在'))
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
      this.form.Jyy = this.$store.state.Login.Username
      this.form.Timsdate = this.getCurrentTime()
      this.Fileitem(this.form.Item_no, 4, this.form.Factory)
      this.FileitemDrawing_number(this.form.Item_no, 1, this.form.Factory)
      this.FQCNumerb(this.form.Numbers)
    }
  },
  mounted () {
  },
  methods: {
    mzTestbox (item, index) {
      this.Mz_day = item.Frequency
      this.Mz_Index = index
      this.yzdialogVisible = true
    },
    Keyenter (item, index) {
      this.form.Parcss[index].Text9 = ''
      this.indeexDomain(item, index)
    },
    ...mapMutations(['UserData']),
    ...mapMutations(['FQCNumerb', 'ConductIs']),
    fileopen (url) {
      this.ConductIs('')
      this.$store.state.Url = ''
      this.$nextTick(() => { // 异步关闭
        setTimeout(() => {
          // 在延迟2秒后执行的代码
          this.$store.state.Url = this.$Upload + '/' + url
          this.ConductIs('ConductIs')
        }, 500)
      })
      // window.open(url, '_blank')
    },
    async Fileitem (item, type, factory) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post(`/api/File/Fileitem?item=${item}&type=${type}&factory=${factory}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.Addtab(this.$t('All.FQC检验单'), 'FQCSeleItem')
          this.RemoveTab(this.$t('All.FQC检验单') + '-' + this.form.Numbers)
          return this.$message.error(item + this.$t('All.文件系统无对应检验基准请维护后在进行作业'))
        }
        loadingInstance.close()
        this.form.Inspection_benchmark = res.Filedata.Filename
        this.form.Inspection_benchmarkurl = res.Filedata.Fileroute
        // this.ckwjiurl = 'http://120.195.84.42:5900/Filesystem/TCWS202309140058/1694677714973.PDF'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
        console.log(error)
      }
    },
    async FileitemDrawing_number (item, type, factory) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post(`/api/File/Fileitem?item=${item}&type=${type}&factory=${factory}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.Addtab(this.$t('All.FQC检验单'), 'FQCSeleItem')
          this.RemoveTab(this.$t('All.FQC检验单') + '-' + this.form.Numbers)
          return this.$message.error(item + this.$t('All.文件系统无对应图纸请维护后在进行作业'))
        }
        loadingInstance.close()
        this.form.Drawing_number = res.Filedata.Filename
        this.form.Drawing_numberurl = res.Filedata.Fileroute
        // this.ckwjiurl = 'http://120.195.84.42:5900/Filesystem/TCWS202309140058/1694677714973.PDF'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
        console.log(error)
      }
    },
    indeexDomain (text, index) {
      this.form.Parcss[index].Text9 = ''
      const valany = []
      if (text.Text2 !== null && text.Text2 !== '' && text.Text2 !== undefined) {
        valany.push(parseFloat(text.Text2))
      }
      if (text.Text3 !== null && text.Text3 !== '' && text.Text3 !== undefined) {
        valany.push(parseFloat(text.Text3))
      }
      if (text.Text4 !== null && text.Text4 !== '' && text.Text4 !== undefined) {
        valany.push(parseFloat(text.Text4))
      }
      if (text.Text5 !== null && text.Text5 !== '' && text.Text5 !== undefined) {
        valany.push(parseFloat(text.Text5))
      }
      if (text.Text6 !== null && text.Text6 !== '' && text.Text6 !== undefined) {
        valany.push(parseFloat(text.Text6))
      }
      // this.form.Parcss[index].Text7 = Math.max.apply(null, valany)
      // this.form.Parcss[index].Text8 = Math.min.apply(null, valany)
      // 最大值
      // 最小值
      this.form.Parcss[index].Text7 = Math.max.apply(Math, valany)
      this.form.Parcss[index].Text8 = Math.min.apply(Math, valany)
      var dataine = this.$PublicJs.Calculation(text.Text, this.form.Parcss[index].Text7, this.form.Parcss[index].Text8)
      this.form.Parcss[index].Text9 = dataine
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = (new Date().getMonth() + 1).toString().padStart(2, '0')
      const dd = new Date().getDate().toString().padStart(2, '0')
      const gettime = yy + '-' + mm + '-' + dd
      return gettime
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    addDomain (item, index) {
      const contr = {
        Date: new Date(),
        Index: index + 1,
        Numbers: this.form.Numbers,
        Number: this.form.Number,
        Product_name: this.form.Product_name, // 品名规格
        Text: item.Text,
        Text1: item.Text1,
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        Text6: '',
        Text7: '',
        Text8: '',
        Text9: ''
      }
      this.form.Parcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    removeDomain (item) {
      var index = this.form.Parcss.indexOf(item)
      if (index !== -1) {
        this.form.Parcss.splice(index, 1)
      }
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    addlist (item, index, value) {
      if (value === this.$t('All.增加')) {
        this.addDomain(item, index)
        this.form.Parcss[index].Text9 = ''
        return
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeDomain(item)
            this.form.Parcss[index].Text9 = ''
          })
          .catch(() => {
            this.form.Parcss[index].Text9 = ''
          })
        return
      }
      if (value === this.$t('All.判定')) {
        this.indeexDomain(item, index)
      }
    },
    async FQC_User (row, index) {
      if (index === 0) {
        for (let indexx = 0; indexx < row.Fqctestlist.length; indexx++) {
          if (row.Fqctestlist[indexx].Determinetype === 1) {
            if (row.Fqctestlist[indexx].Determine === '' || row.Fqctestlist[indexx].Determine === null || row.Fqctestlist[indexx].Determine === undefined) {
              return this.$message.error(`${row.Fqctestlist[indexx].Title}${this.$t('All.项实验室没有做出判断系统无法进行送呈只能暂存')}`)
            }
          }
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        row.State = index
        row.Department = 'FQC'
        const fqcformpost = {
          UserData: this.$store.state.Login,
          FQC_User: row
        }
        const { data: res } = await this.$http.post('/api/FQC/NewUserJyyAdd', fqcformpost)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.UserData(null)
        this.Addtab(this.$t('All.新FQC检验单'), 'NewFQCSeleItem')
        this.RemoveTab(this.$t('All.新FQC检验单') + '-' + row.Numbers)
        this.Rendw(this.$t('All.待审'), 'Examine', 'UserJyySele')
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    text9pass (index, state) {
      this.form.Parcss[index].Text9 = state
    },
    Testbox (item, index) {
      this.Test_Item = item
      this.Test_Index = index
      this.Test_Supplier = this.form.Customer
      this.Test_Product_name = this.form.Product_name
      this.Test_Numbers = ''
      this.Texttable = true
    },
    Texttablebool () {
      this.Texttable = false
    },
    Texttablefunadd (index, row) {
      this.form.Fqctestlist[index].Remarks = row
      this.form.Fqctestlist[index].Determine = ''
      this.Texttable = false
    },
    chuangjcompany () {
      var inputValue = this.form.Company
      // 移除非字母字符
      inputValue = inputValue.replace(/[^A-Za-z]/g, '')
      // 转换为大写
      inputValue = inputValue.toUpperCase()
      this.form.Company = inputValue
    },
    lookshiyan (row, index) {
      if (row.Remarks === '' || row.Remarks.length < 0) return this.$message.error(this.$t('All.请先填写送测单'))
      if (this.isdisabled) {
        this.Test_Numbers = row.Remarks
        this.Texttable = true
        return
      }
      this.$confirm(this.$t('All.查看实验单重新做申请实验单'), this.$t('All.提示'), {
        cancelButtonText: this.$t('All.查看'),
        confirmButtonText: this.$t('All.重做实验'),
        type: 'warning'
      })
        .then(() => {
          this.Testbox(row, index)
        })
        .catch(() => {
          this.Test_Numbers = row.Remarks
          this.Texttable = true
        })
    },
    async Gettestbaogao () {
      try {
        if (this.Mz_Numbers.trim().length === 0) {
          this.form.Fqctestlist[this.Mz_Index].Remarks = ''
          this.form.Fqctestlist[this.Mz_Index].Determine = ''
          this.yzdialogVisible = false
          return this.$message.error(this.$t('All.清空订单号成功'))
        }
        const { data: res } = await this.$http.get('/api/Newiqc/Gettestbaogao?Numbers=' + this.Mz_Numbers + '&Day=' + this.Mz_day)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.form.Fqctestlist[this.Mz_Index].Remarks = this.Mz_Numbers
        this.form.Fqctestlist[this.Mz_Index].Determine = res.response.Results
        this.Mz_Numbers = ''
        this.Mz_Index = ''
        this.Mz_day = ''
        this.yzdialogVisible = false
      } catch (error) {
        alert(error)
      }
    },
    // 失去焦点时处理空值
    finalFormat () {
      if (this.form.Case_numbe === '') {
        this.form.Case_numbe = null // 设为 null 避免存入空字符串
      } else {
        const dataarrny = this.$PublicJs.Samplefun(this.form.Case_number, this.form.Warehousing, this.form.Sampling_type)
        this.form.Sampling = dataarrny.Samplecount
        this.form.Samplingboxid = dataarrny.Boxnumberstr
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wbobye {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
}

.el-col {
  border: 1px solid;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 4.5vw;
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-content {
  min-height: 36px;
}

.bg-purple-light {}

.row-bg {
  background-color: #f9fafc;
}
</style>
